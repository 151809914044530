@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500");

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

:root {
  /* colors */
  --body-bg-color: #eee;
  --sidebar-bg-color: #f4f4f4;
  --text-color: #333;
  --title-color: #111;
  --date-color: #1f94c2;

  --icon-color: var(--text-color);
  --circle-color: #aaa;
  --circle-color-plain: #555;

  /* fonts */
  --body-font: "Poppins", "Sans-serif";
  --h1-font-size: 1.7rem;
  --h2-font-size: 1.3rem;
  --h3-font-size: 1.1rem;
  --h4-font-size: 1rem;

  /* helpers */
  --spacing-1: 0.4rem;
  --spacing-2: 0.8rem;
  --spacing-3: 1.2rem;
  --spacing-4: 1.5rem;
  --spacing-5: 2rem;

  --transition-duration-mode: 0.7s;
}

/* Transition pour changement de mode */
* {
  transition: color var(--transition-duration-mode) ease-in-out;
  transition: background-color var(--transition-duration-mode) ease-in-out;
}

body {
  font-family: var(--body-font);
  font-weight: 200;
  color: var(--text-color);
  background-color: var(--body-bg-color);
}

h1,
h2,
h3 {
  margin-bottom: var(--spacing-1);
  font-weight: 400;
  color: var(--title-color);
}

h1 {
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

h2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-4);
  font-size: var(--h2-font-size);
  text-transform: uppercase;
  letter-spacing: 4px;
}

h3 {
  font-size: var(--h3-font-size);
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dark button {
  color: var(--dark-text-color);
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.dark a {
  color: var(--dark-text-color);
}

p {
  margin-bottom: var(--spacing-2);
}

ul {
  padding-left: 1.3rem;
}

li {
  margin-bottom: var(--spacing-2);
}

/* helpers */
.mb3 {
  margin-bottom: var(--spacing-3);
}

.mb5 {
  margin-bottom: var(--spacing-5);
}

/* grids */
.grid__row {
  margin-bottom: var(--spacing-3);
}

@media screen and (min-width: 768px) {
  .grid__container {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .grid__row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 0;
  }
}

/* layouts */
.sidebar {
  padding: 1.5rem;
  background-color: var(--sidebar-bg-color);

}

.actions {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.main {
  padding: 1.5rem;
}

/* icons */
.circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 50%;
  background-color: var(--circle-color);
}

.circle-plain {
  background-color: var(--circle-color-plain);
}