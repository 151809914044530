.grid__item,
.grid__item p {
  margin-bottom: var(--spacing-1);
}

.grid__date {
  font-weight: 500;
  margin-top: 8px;
}

.grid__title {
  margin-bottom: 0;
}

.grid__location {
  font-weight: 300;
  color: var(--date-color);
}

.grid__mission {
  margin-bottom: var(--spacing-1);
}